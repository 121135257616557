import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Alert, Form } from "react-bootstrap";
import { axiosConfig } from "../axiosAPI";
import axios from "axios";
function Transactions() {
  const [customers, setCustomers] = useState([]);
  const [balance, setBalance] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [loading, setLoading] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [transactions, setTransactions] = useState([]);
  // const [id, setid] = useState('');

  const auth = useAuth();
  const [driver, setDriver] = useState([]);

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://dog.addictaco.com/api/v2/transactions",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      console.log("response", response.data.data.result);
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const getDriver = async () => {
    try {
      const response = await axiosConfig.get(
        "https://dog.addictaco.com/api/v2/users/drivers",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setDriver(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const getBalance = async (id) => {
    if (id) {
      try {
        const response = await axiosConfig.get(
          `https://dog.addictaco.com/api/v2/transactions/balance/${id}`,
          {
            headers: {
              Authorization: "Bearer " + auth.token,
              "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
            },
          }
        );
        setBalance(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } else {
      setBalance(0);
    }
  };

  useEffect(() => {
    getData();
    getDriver();
    // getBalance()
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };


  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };

  const [visible, setVisible] = useState(false);
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Driver</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => { setVisible(false); setBalance(0); }}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          setVisible(false);
          setBalance(0);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [formData, setFormData] = useState({
    driverUuid: [],
    type: [],
    amount: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'driverUuid') {

      getBalance(e.target.value)
    }
    console.log(e.target.value);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const response = await axiosConfig
      .post(
        "https://dog.addictaco.com/api/v2/transactions",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    setFormData("");
  };


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a>
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "dateCreated",
                  "driverFullName",
                  "typeLocalized",
                  "amount",
                  "description",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
                dataKey="uuid"
                cellSelection
                selectionMode="single"
                onSelectionChange={(e) => {
                  setSelectedCell(e.value);
                  setSelectedItem(e.value.value);
                }}
              >
                <Column
                  field="driverFullName"
                  header="DRIVER NAME"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="dateCreated"
                  header="DATE CREATED"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="typeLocalized"
                  header="TYPE"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="amount"
                  header="AMOUNT"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="description"
                  header="DESCRIPTION"
                  style={{ width: "20%" }}
                ></Column>
                {/*  new column for actions (edit & delete) */}
              </DataTable>
              <Dialog
                visible={visible}
                modal
                header={headerElement}
                footer={footerContent}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible) return;
                  setVisible(false);
                  setBalance(0);
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Driver</Form.Label>
                        <select
                          onChange={handleChange}
                          name="driverUuid"
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {driver.map((x, index) => (
                            <option value={x.uuid} key={index}>
                              {x.fullName}
                            </option>
                          ))}
                        </select>
                        <span>Balance: {balance?.balance}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Type</Form.Label>
                        <select
                          onChange={handleChange}
                          name="type"
                          className="form-select"
                        >
                          <option>Select</option>
                          <option value={"Pay"}>Pay</option>
                          <option value={"Collect"}>Collect</option>
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          name="amount"
                          placeholder="amount"
                          value={formData.amount}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="description"
                          placeholder="text"
                          value={formData.description}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transactions;
