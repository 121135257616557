import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";

function Month() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend
  );

  const [revenue, setRevenue] = useState([]);
  const [users, setUsers] = useState([]);
  const auth = useAuth();

  const getRevenues = async () => {
    try {
      const response = await axiosConfig.get(
        "https://dog.addictaco.com/api/v2/dashboard/revenues",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setRevenue(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
useEffect(() => {
  getRevenues();
  // getUsers();
}, []);

  const data = {
    labels: revenue.map((data) => data.monthName),
    datasets: [
      {
        label: "Items",
        data: revenue.map((data) => data.amount),
        borderColor: "black",
        backgroundColor: "white",
      },
    ],
  };
  return (
    <Line
      data={data}
      options={{
        responsive: true,
      }}
    />
  );
}

export default Month;
