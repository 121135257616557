import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import MyInput from "../Charts/Month";
import DonutChart from "../Charts/Option";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";
import MOnth from "../Charts/Month";
import ChartComponent from "../Charts/Month";
import Month from "../Charts/Month";
import Option from "../Charts/Option";

function Dashboard() {
  const [post, setPost] = useState({
    requestCount: "",
    totalPayments: "",
    userCount: "",
  });
  const [driver, setDriver] = useState([]);
  const auth = useAuth();

  const getDrivers = async () => {
  try {
    const response = await axiosConfig.get(
      "https://dog.addictaco.com/api/v2/dashboard/latest-drivers",
      {
        headers: {
          Authorization: "Bearer " + auth.token,
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      }
    );
    setDriver(response.data.data);
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  }
  };


useEffect(() => {
  getDrivers()
}, []);


useEffect(() => {
  axios
    .get("https://dog.addictaco.com/api/v2/dashboard/summary", {
      headers: {
        Authorization: "Bearer " + auth.token,
        "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
      },
    })
    .then((response) => {
      setPost(response.data.data);
    });
}, []);

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="charts-card">
            <div className="row">
              <div className="col-md-4">
                <div className="card-details">
                  <h2>
                    Number of requests <span>{post?.requestCount}</span>
                  </h2>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-details">
                  <h2>
                    Number of Users <span>{post?.userCount}</span>
                  </h2>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-details">
                  <h2>
                    Total Payment <span>{post?.totalPayments}</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-details">
                  <Option />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card-details">
                <Month />
                </div>
              </div>
              <div className="col-md-12">
                <div className="card-details">
                  <div className="row">
                    <div className="col-md-12">
                      <h3>Drivers last week</h3>
                    </div>
                    {driver.map((x, index) => (
                      <div className="col-md-3 col-sm-6" key={index}>
                        <div className="driver-id">{x.fullName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
