import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import Table from "../Table/Table";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";

function Request() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://dog.addictaco.com/api/v2/dashboard/rides",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);

  
  // console.log(selectedItem);
  const gotToNewPage = (rowData) => {
    return <a
      className="edit"
      onClick={(e) => {
        navigate(`/DetailsRequests/${rowData.uuid}`, { state: { item: rowData } });
        console.log(rowData)
      }}
    >
      details
    </a>;
  };

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "passengerFullName",
                  "driverFullName",
                  "fare",
                  "date",
                  "status",
                  "type",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
              >
                <Column
                  field="type"
                  header="type"
                  style={{ width: "14.28%" }}
                ></Column>
                <Column
                  field="passengerFullName"
                  header="passenger"
                  style={{ width: "14.28%" }}
                ></Column>
                <Column
                  field="driverFullName"
                  header="dirver"
                  style={{ width: "14.28%" }}
                ></Column>
                <Column
                  field="fare"
                  header="ride cost"
                  style={{ width: "14.28%" }}
                ></Column>
                <Column
                  field="date"
                  header="date"
                  style={{ width: "14.28%" }}
                ></Column>
                <Column
                  field="uuid"
                  body={gotToNewPage}
                  header="Details"
                  style={{ width: "14.28%" }}
                ></Column>
                <Column
                  field="status"
                  header="status"
                  style={{ width: "14.28%" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Request;
