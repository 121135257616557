import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";
import { useNavigate } from "react-router-dom";

function Details(params) {
  const location = useLocation();
  const [post, setPost] = useState({});
  useEffect(() => {
    setPost(location.state.item);
  }, []);
  const auth = useAuth();
  const navigate = useNavigate();



  const approveUser = async (uuid) => {
    const response = await axiosConfig
      .put(
        "https://dog.addictaco.com/api/v2/users/drivers/" + uuid + "/approve",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        // getData();
          navigate("/Driver");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid)
  };

  const rejectUser = async (uuid) => {
    const response = await axiosConfig
      .put(
        "https://dog.addictaco.com/api/v2/users/drivers/" + uuid + "/reject",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        // getData();
          navigate("/Driver");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid);
  };


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="details">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1> Drivers Details</h1>
                    {post?.applicationStatus == "Pending" ? (
                      <>
                        <div className="action-table">
                          <a
                            className="approve"
                            onClick={(e) => {
                              approveUser(post?.uuid);
                            }}
                          >
                            <i className="fa fa-check"></i>
                          </a>
                          <a
                            className="delete"
                            onClick={(e) => {
                              rejectUser(post?.uuid);
                            }}
                          >
                            <i className="fa fa-close"></i>
                          </a>
                        </div>
                      </>
                    ) : post?.applicationStatus == "Approved" ? (
                      <>
                        <p>Approved</p>
                      </>
                    ) : (
                      <>
                        <p>Rejected</p>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <h2>
                    Full Name: <span>{post?.fullName}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    Phone Number: <span>{post?.phoneNumber}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    National ID: <span>{post?.nationalID}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    Car Model: <span>{post?.carModel}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    Car License: <span>{post?.carLicense}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    Have A Dog: <span>{post?.haveADog?.toString()}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <img
                    src={`http://dog.addictaco.com${post?.profilePicturePath}`} alt="diver"
                  />
                </div>
                <div className="col-md-12">
                  <h2>Files:</h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    <a href={"https://app.dogdriveapp.com" + post?.frontNationalIDPath} target="_blank">Front National ID Path</a>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    <a href={"https://app.dogdriveapp.com" + post?.backNationalIDPath} target="_blank">Back National ID Path</a>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    <a href={"https://app.dogdriveapp.com" + post?.frontDriverLicensePath} target="_blank">Front Driver License Path</a>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    <a href={"https://app.dogdriveapp.com" + post?.backDriverLicensePath} target="_blank">Back Driver License Path</a>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    <a href={"https://app.dogdriveapp.com" + post?.frontCarLicensePath} target="_blank">Front Car ID Path</a>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    <a href={"https://app.dogdriveapp.com" + post?.backCarLicensePath} target="_blank">Back Car ID Path</a>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                  <a href={"https://app.dogdriveapp.com" + post?.criminalRecordPath} target="_blank">Criminal Record Path</a>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    <a href={"https://app.dogdriveapp.com" + post?.drugTestPath} target="_blank">Drug Test Path</a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
