import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useAuth } from "../AuthProvider";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { axiosConfig } from "../axiosAPI";

function Setting() {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [area, setArea] = useState([]);
  const [post, setPost] = useState({
    pricePerKilometer: "",
    pricePerMinute: "",
    minFare: "",
    companyPercentage: "",
  });
  const [editRow, setEditRow] = useState(null);
  const [editbreeds, setEditBreeds] = useState();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://dog.addictaco.com/api/v2/dog-breeds",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setData(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  
  const getArea = async () => {
    try {
      const response = await axiosConfig.get(
        "https://dog.addictaco.com/api/v2/areas",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setArea(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };


  const [formData, setFormData] = useState({
    displayName_Ar: "",
    displayName_En: "",
  });

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();  
    try {
      const response = await axiosConfig.post(
        "https://dog.addictaco.com/api/v2/dog-breeds",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      getData();
      console.log(response.data);
      } catch (error) {
      console.error( error);
      }
      setFormData("");
    };

  const deleteUser = uuid => {
    fetch(`https://dog.addictaco.com/api/v2/dog-breeds/${uuid}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
        "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
      },
    })
      .then(response => response.json())
      .then(() => {
        getData()
      })
  }
  
  const editUser = async uuid => {
    const response = await axiosConfig
      .put(
        "https://dog.addictaco.com/api/v2/dog-breeds/" + editbreeds,
        {
          ...formData,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        console.log(res);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSubmit2 = async (e) => {
    // e.preventDefault();  
    try {
      const response = await axiosConfig.post(
        "https://dog.addictaco.com/api/v2/areas",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      getArea();
      console.log(response.data);
      } catch (error) {
      console.error( error);
      }
      setFormData("");
    };

  const deleteUser2 = uuid => {
    fetch(`https://dog.addictaco.com/api/v2/areas/${uuid}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
        "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
      },
    })
      .then(response => response.json())
      .then(() => {
        getArea()
      })
  }
  
  const editUser2 = async uuid => {
    const response = await axiosConfig
      .put(
        "https://dog.addictaco.com/api/v2/areas/" + editRow,
        {
          ...formData,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        console.log(res);
        getArea();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => { handleSubmit(); setVisible(false);}}
        className="save"
        autoFocus
      />
    </div>
  );

  
  const [visible2, setVisible2] = useState(false);
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => { handleSubmit2(); setVisible2(false);}}
        className="save"
        autoFocus
      />
    </div>
  );
  
  const [visible3, setVisible3] = useState(false);
  const footerContent3 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible3(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => { editUser(); setVisible3(false);}}
        className="save"
        autoFocus
      />
    </div>
  );
  
  const [visible4, setVisible4] = useState(false);
  const footerContent4 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible4(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => { editUser2(); setVisible4(false);}}
        className="save"
        autoFocus
      />
    </div>
  );

  
  const handlePost = async uuid => {
    const response = await axiosConfig
      .put(
        "https://dog.addictaco.com/api/v2/settings",
        {
          ...post,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setPost(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getData();
    getArea();
  }, []);

  useEffect(() => {
    axiosConfig
      .get("https://dog.addictaco.com/api/v2/settings", {
        headers: {
          Authorization: "Bearer " + auth.token,
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((response) => {
        setPost(response.data.data);
        console.log(response.data.data);
        console.log(post);
      });
  }, []);

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="setting">
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Dog Breeds</Tab>
                <Tab>Areas</Tab>
              </TabList>

              <TabPanel>
                <div className="General">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Price Per Kilometer</label>
                          <input
                            type="number"
                            step={0.1}
                            placeholder="Price Per Kilometer"
                            value={post?.pricePerKilometer}
                            // value={formValue.pricePerKilometer}
                            onChange={handleChange2}
                            id="pricePerKilometer"
                            name="pricePerKilometer"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Price Per Minute</label>
                          <input
                            type="number"
                            step={0.1}
                            placeholder="Price Per Minute"
                            value={post?.pricePerMinute}
                            // value={formValue.pricePerMinute}
                            onChange={handleChange2}
                            id="pricePerMinute"
                            name="pricePerMinute"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Min Fare</label>
                          <input
                            type="number"
                            step={0.1}
                            placeholder="Min Fare"
                            value={post?.minFare}
                            // value={formValue.pricePerMinute}
                            onChange={handleChange2}
                            id="minFare"
                            name="minFare"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="form-group">
                          <label>Company Percentage</label>
                          <input
                            type="number"
                            step={0.1}
                            placeholder="Company Percentage"
                            value={post?.companyPercentage}
                            // value={formValue.pricePerMinute}
                            onChange={handleChange2}
                            id="companyPercentage"
                            name="companyPercentage"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 text-end mt-5">
                        <a
                          className="save"
                          href="#!"
                          onClick={() => {
                            handlePost();
                          }}
                        >
                          Save
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </TabPanel>
              <TabPanel>
                <a className="add-breeds" onClick={() => setVisible(true)}>
                  <i className="fa fa-plus"></i>
                </a>
                {data.map((x, index) => (
                  <div className="breeds mt-3" key={index}>
                    <h4>
                      <span>{x.displayName_Ar}</span> ||{" "}
                      <span>{x.displayName_En}</span>
                    </h4>
                    <div className="actions">
                      <a
                        href="#!"
                        className="delete"
                        id={x.uuid}
                        onClick={() => deleteUser(x.uuid)}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                      <a
                        href="#!"
                        className=""
                        id={x.uuid}
                        onClick={() => {
                          setVisible3(true);
                          setEditBreeds(x.uuid);
                        }}
                      >
                        <i className="fa fa-pen"></i>
                      </a>
                    </div>
                  </div>
                ))}
                <Dialog
                  visible={visible}
                  modal
                  footer={footerContent}
                  style={{ width: "50rem" }}
                  onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <label>AR:</label>
                    <input
                      type="text"
                      name="displayName_Ar"
                      value={formData.displayName_Ar}
                      onChange={handleChange}
                    />
                    <br />
                    <label>EN:</label>
                    <input
                      type="text"
                      name="displayName_En"
                      value={formData.displayName_En}
                      onChange={handleChange}
                    />
                    <br />
                    {/* <button>Submit</button> */}
                  </form>
                </Dialog>
                <Dialog
                  visible={visible3}
                  modal
                  footer={footerContent3}
                  style={{ width: "50rem" }}
                  onHide={() => {
                    if (!visible3) return;
                    setVisible3(false);
                  }}
                >
                  <form>
                    <label>AR:</label>
                    <input
                      type="text"
                      name="displayName_Ar"
                      value={formData.displayName_Ar}
                      onChange={handleChange}
                    />
                    <br />
                    <label>EN:</label>
                    <input
                      type="text"
                      name="displayName_En"
                      value={formData.displayName_En}
                      onChange={handleChange}
                    />
                    <br />
                    {/* <button>Submit</button> */}
                  </form>
                </Dialog>
              </TabPanel>
              <TabPanel>
                <a className="add-breeds" onClick={() => setVisible2(true)}>
                  <i className="fa fa-plus"></i>
                </a>
                {area.map((x, index) => (
                  <div className="breeds mt-3" key={index}>
                    <h4>
                      <span>{x.displayName_Ar}</span> ||{" "}
                      <span>{x.displayName_En}</span>
                    </h4>
                    <div className="actions">
                      <a
                        href="#!"
                        className="delete"
                        id={x.uuid}
                        onClick={() => deleteUser2(x.uuid)}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                      <a
                        href="#!"
                        className=""
                        id={x.uuid}
                        onClick={() => {
                          setVisible4(true);
                          setEditRow(x.uuid);
                        }}
                      >
                        <i className="fa fa-pen"></i>
                      </a>
                    </div>
                  </div>
                ))}
                <Dialog
                  visible={visible2}
                  modal
                  footer={footerContent2}
                  style={{ width: "50rem" }}
                  onHide={() => {
                    if (!visible2) return;
                    setVisible2(false);
                  }}
                >
                  <form onSubmit={handleSubmit2}>
                    <label>AR:</label>
                    <input
                      type="text"
                      name="displayName_Ar"
                      value={formData.displayName_Ar}
                      onChange={handleChange}
                    />
                    <br />
                    <label>EN:</label>
                    <input
                      type="text"
                      name="displayName_En"
                      value={formData.displayName_En}
                      onChange={handleChange}
                    />
                    <br />
                    {/* <button>Submit</button> */}
                  </form>
                </Dialog>
                <Dialog
                  visible={visible4}
                  modal
                  footer={footerContent4}
                  style={{ width: "50rem" }}
                  onHide={() => {
                    if (!visible4) return;
                    setVisible4(false);
                  }}
                >
                  <form>
                    <label>AR:</label>
                    <input
                      type="text"
                      name="displayName_Ar"
                      value={formData.displayName_Ar}
                      onChange={handleChange}
                    />
                    <br />
                    <label>EN:</label>
                    <input
                      type="text"
                      name="displayName_En"
                      value={formData.displayName_En}
                      onChange={handleChange}
                    />
                    <br />
                    {/* <button>Submit</button> */}
                  </form>
                </Dialog>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default Setting;
